import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';
import css from './UserNavBar.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useSelector } from 'react-redux';
import {
  IconAmenities,
  NamedLink,
  AvatarMedium,
  IconCollection,
} from '../../components';
import { useState } from 'react';

const UserNavBar = props => {
  const {
    className,
    rootClassName,
    selectedPageName,
    listing,
    currentUser,
    params,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const downArrow = <IconCollection name="ARROW_dOWN" />;
  const arrowTop = <IconCollection name="ARROW_TOP" />;

  const myData = [
    {
      collapsibleTitle: 'Account Settings',
      // note : the collapsibleTitle and the type we pass for i.e <IconAmenities type=""/> will be same .
      childContent: [
        { name: 'ProfileSettingsPage', label: 'Personal Information' },
        { name: 'PaymentMethodsPage', label: 'Payments & payouts', params: params || { tab: 'payment-methods' } },
        { name: 'AddressPage', label: 'Address' },
      ],
    },
    {
      collapsibleTitle: 'Referrals',
      childContent: [
        { name: 'LandingPage', label: 'Referrals Information' },
        { name: 'LandingPage', label: 'Referrals and payout' },
        { name: 'AddressPage', label: 'Search' },
      ],
    },
    {
      collapsibleTitle: 'Support',
      childContent: [
        { name: 'LandingPage', label: 'How Runtize works' },
        { label: 'Help Center', onClick: () => window.Tawk_API.toggle() },
        { name: 'ContactUsPage', label: 'Contact Us' },
      ],
    },
  ];

  const userNavBarData =
    myData &&
    myData.length &&
    myData.map((ele, i) => {
      let myTitle = ele.collapsibleTitle;
      return (
        <div className={css.mainMenu} key={i}>
          <IconAmenities type={myTitle} />
          <Collapsible trigger={myTitle} open={i < 1 && true}>
            {myTitle != 'Referrals' &&
              ele.childContent.map((paramsData, i) => {
                const { name, params, label } = paramsData;
                return (
                  name ?
                  <NamedLink name={name} params={params || {}} key={i}>
                    <div className={css.collapsibleLink}>
                      <h4>{label}
                        <IconCollection name="ARROW_USER_NAV" />
                      </h4>
                    </div>
                  </NamedLink>
                    : <div className={css.collapsibleLink} key={i} onClick={paramsData.onClick}>
                      <h4>{label}
                        <IconCollection name="ARROW_USER_NAV" />
                      </h4>
                    </div>
                );
              })}
          </Collapsible>
          {/* <div className={css.arrowIcon}> {downArrow} {arrowTop}</div> */}
        </div>
      );
    });

  const userImage = <AvatarMedium user={currentUser} />;
  const userData =
    currentUser && currentUser.attributes && currentUser.attributes.profile;
  const userFullName =
    (userData && currentUser.attributes.profile.firstName) +
    ' ' +
    (userData && currentUser.attributes.profile.lastName);

  const zipLocation = currentUser && currentUser.attributes?.profile?.protectedData?.zipLocation;
  const formetZipLocation = zipLocation && zipLocation.length && zipLocation?.split(',');
  const updatedAddress = formetZipLocation ? formetZipLocation[1].split(" ") : null
  return (
    <>
      <div className={css.profileContent}>
        {userImage}
        <div>
          <p>{userFullName}</p>
          <div className={css.locationContent}>
            <IconCollection name="MAP_LOCATION" />
            {formetZipLocation ? formetZipLocation[0] : null},{updatedAddress ? updatedAddress[1] : null}

          </div>
        </div>
      </div>
      {userNavBarData}
    </>
  );
};

UserNavBar.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavBar.propTypes = {
  className: string,
  rootClassName: string,
  // selectedPageName: string.isRequired,
};

export default UserNavBar;

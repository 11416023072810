import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsInFirstReviewBy,
  TRANSITION_CANCEL_BY_CUSTOMER_AFTER_ACCEPT,
  TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT,
  TRANSITION_CANCEL_BY_PROVIDER_AFTER_ACCEPT,
  TRANSITION_EXPIRE,
  TRANSITION_ACCEPT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_ENQUIRE,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, REVIEW_TYPE_OF_CUSTOMER } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  AvatarMedium,
  Button,
  IconCollection,
  InlineTextButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  Modal,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';
import moment from 'moment';
import { category } from '../../marketplace-custom-config';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';
import { priceData } from '../ListingCardInbox/ListingCardInbox';
const categoryLabel = (category, key) => {
  const cat = category.find(c => c.key === key);
  return cat ? cat.value : key;
};

const isCancelAfterAcceptOrInstantEnabled = false;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = (
    <UserDisplayName user={currentProvider} intl={intl} />
  );
  const customerDisplayName = (
    <UserDisplayName user={currentCustomer} intl={intl} />
  );

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id &&
    currentCustomer.id &&
    currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id &&
    currentProvider.id &&
    currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      openModal: false,
      ModalType: '',
      isModalOpen: false,
      openModal2: false,
      isConfirmationOpen: false,
      isMenuOpen: null,
      timeDifference: null,
      hourss: null,
      mints: null,
      percentageProgress: 0,
      difference: {},
      timeInmints: null,

    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';
    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.handleopenModal = this.handleopenModal.bind(this);
    this.handleCancelBooking = this.handleCancelBooking.bind(this);
    this.handleAcceptBooking = this.handleAcceptBooking.bind(this);
    // this.onContactUser = this.onContactUser.bind(this);
    this.calculateDifference = this.calculateDifference.bind(this);
    this.handlePickupAddressInfoModal = this.handlePickupAddressInfoModal.bind(this);
  }

  handlePickupAddressInfoModal = () => {
    this.setState({ openPickupAddressInfoModal: !this.state.openPickupAddressInfoModal });
  };

  calculateDifference = () => {
    const { transaction, bookingData } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { start, end } = currentTransaction?.booking?.attributes || {};
    const currentDate = moment();
    if (currentDate.isBefore(start)) {
      const timeDifference = Math.abs(start - currentDate); // Difference in milliseconds
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      this.setState({
        difference: {
          days,
          hours,
          minutes,
          seconds,
        },
      });
    } else {
      // Set default values or handle the case when the current date is not before the start date
      this.setState({
        difference: {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        },
      });
    }
  };

  componentDidMount() {
    const { transaction, bookingData } = this.props;
    const { days, hours: hrs, mintues, seconds } = this.state.difference;
    const currentTransaction = ensureTransaction(transaction);
    const { bookingStart, bookingEnd } = currentTransaction.attributes.protectedData.bookingData || {};
    if (currentTransaction?.attributes?.lastTransition != "transition/enquire") {
      Object.assign(transaction.booking.attributes, {
        start: bookingStart ? moment(bookingStart).toDate() : transaction.booking.attributes.start,
        end: bookingEnd ? moment(bookingEnd).toDate() : transaction.booking.attributes.end,
        displayEnd: bookingEnd ? moment(bookingEnd).toDate() : transaction.booking.attributes.end,
        displayStart: bookingStart ? moment(bookingStart).toDate() : transaction.booking.attributes.start,
      })
    }

    const { startTiming } = bookingData || {};
    this.isMobSaf = isMobileSafari();
    const now = new Date();
    // Set the time to 8:00 AM for today and tomorrow
    const today8AM = new Date(now);
    // today8AM.setHours(now.getHours(), 0, 0, 0);
    const test = startTiming && startTiming?.split(":")[0];

    const timeStr1 = startTiming && startTiming?.split(" ")[0];
    const [hourStr1, minuteStr1] = timeStr1 ? timeStr1.split(":") : [];
    let hour1 = parseInt(hourStr1, 10);
    if (hour1 < 12 && startTiming && startTiming?.split(" ")[1] == 'PM') {
      hour1 += 12;
    }
    const bookingEndDate = moment(currentTransaction?.booking?.attributes.end).format("DD MMM YYYY HH:mm");
    const bookingStartDate = moment(currentTransaction?.booking?.attributes.start).format("DD MMM YYYY HH:mm");
    const uu = moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day');
    const tomorrow8AM = new Date(now);
    tomorrow8AM.setDate(now.getDate() + uu);
    tomorrow8AM.setHours(hour1, 0, 0, 0); // Set time to 8:00 AM

    // Calculate the time difference in milliseconds
    const differenceInMilliseconds = tomorrow8AM - today8AM;
    // Convert the difference to hours and minutes
    const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const remainingMilliseconds1 = differenceInMilliseconds % (1000 * 60 * 60);
    const differenceInMinutes1 = Math.round(remainingMilliseconds1 / (1000 * 60));

    this.setState({ timeDifference: differenceInHours, timeInmints: differenceInMinutes1 });

    // Set the time to 8:00 AM for today
    const today8AM1 = new Date(now);
    // today8AM1.setHours(now.getHours(), 0, 0, 0);

    // Set the time to 8:00 AM for tomorrow
    const tomorrow8AM1 = new Date(now);
    tomorrow8AM1.setDate(now.getDate());
    const timeStr = startTiming && startTiming?.split(" ")[0];
    const [hourStr, minuteStr] = timeStr1 ? timeStr1.split(":") : [];
    let hour = parseInt(hourStr, 10);

    // Convert to 24-hour format
    if (hour < 12 && startTiming && startTiming?.split(" ")[1] == 'PM') {
      hour += 12;
    }

    const convertedTime = `${hour.toString().padStart(2, "0")}:${minuteStr}`;
    const time = parseInt(convertedTime.split(":")[0]) + 5;
    tomorrow8AM1.setHours(time, 0, 0, 0); // Set time to 8:00 AM
    // Calculate the time difference in milliseconds
    const differenceInMilliseconds1 = tomorrow8AM1 - today8AM1;
    // Convert the difference to hours or any other format you may need
    const differenceInHours1 = differenceInMilliseconds1 / (1000 * 60 * 60);
    const remainingMilliseconds = differenceInMilliseconds1 % (1000 * 60 * 60);
    const differenceInMinutes = Math.round(remainingMilliseconds / (1000 * 60));
    this.setState({ hourss: Math.round(differenceInHours1), mints: differenceInMinutes });
    const currentDate = moment();
    const diffBetweenDays = (days == 0 && hrs == 0);
    const createdAtDates = transaction?.attributes?.createdAt;
    const createdAtDate = diffBetweenDays ? bookingStartDate : createdAtDates;
    const targetDateStr = bookingEndDate;
    const createdDate = moment(createdAtDate, 'DD MMM YYYY HH:mm');
    const targetDate = moment(targetDateStr, 'DD MMM YYYY HH:mm');

    // Calculate the time remaining in milliseconds
    const timeRemainingMs = targetDate.diff(currentDate);

    // Calculate the total time difference in milliseconds
    const totalTimeDifferenceMs = targetDate.diff(createdDate);
    // Calculate the percentage progress
    const percentage = ((totalTimeDifferenceMs - timeRemainingMs) / totalTimeDifferenceMs) * 100;
    // Ensure the percentage is within the range of 0 to 100
    const clampedPercentage = Math.max(Math.min(percentage, 100), 0);
    this.setState({ percentageProgress: clampedPercentage });
    this.calculateDifference();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then((r) =>
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true })
      )
      .catch((e) => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then((messageId) => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch((e) => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleCancelBooking(id) {
    const {
      onCancelBookingAfterAcceptByCustomer,
      onCancelBookingAfterAcceptByProvider,
      onCancelBookingBeforeAcceptByCustomer,
      onDeclineSale,
      transactionRole,
      transaction
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const isAcceptedState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ACCEPT;
    const instantBooking = currentListing?.attributes?.publicData?.instantBooking;

    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    isCustomer && instantBooking == "instantBooking" ?
      onCancelBookingAfterAcceptByCustomer(id)
      : isProvider && instantBooking == "instantBooking" ?
        onCancelBookingAfterAcceptByProvider(id)
        : isCustomer && instantBooking == "delivery" && !isAcceptedState ?
          onCancelBookingBeforeAcceptByCustomer(id)
          : isCustomer && instantBooking == "delivery" && isAcceptedState ?
            onCancelBookingAfterAcceptByCustomer(id)
            : isProvider && instantBooking == "delivery" && isAcceptedState ?
              onCancelBookingAfterAcceptByProvider(id)
              :
              onDeclineSale(id);

    this.setState({ openModal: false });
  }

  handleAcceptBooking(id) {
    this.props.onAcceptSale(id);
    setTimeout(() => {
      this.setState({ openModal: false });
      window.location.reload();
    }, 2000);
  }

  handleopenModal(val) {
    if (val == 'cancel-booking') {
      this.setState({ openModal: true, ModalType: 'Cancel' });
    } else if (val == 'accept-booking') {
      this.setState({ openModal: true, ModalType: 'Accept' });
    } else if (val == 'contact-support') {
      this.setState({ openModal: true, ModalType: 'Support' });
    }
  }

  render() {
    const { percentageProgress } = this.state;
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      nextTransitions,
      markCancelPropsByCustomer,
      markCancelPropsByProvider,
      markCancelPropsByCustomerBeforeAccept,
      bookingData,
      isMobile,
      reviews,
    } = this.props;
    const { days, hours: hour, minutes: mint, seconds } = this.state.difference;
    const currentTransaction = ensureTransaction(transaction);
    const { start, end } = currentTransaction?.booking?.attributes || {};
    const today = new Date();
    const { selectedTime, diffBetweenDays, startTiming } = bookingData || {};
    const isConfirmedState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_CONFIRM_PAYMENT;
    const hasBeenAccepted = currentTransaction?.attributes.transitions.find(transition => transition.transition === TRANSITION_ACCEPT);
    const isEnquiryState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ENQUIRE
    const isCompleteState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_COMPLETE
    const reviewByCusmoter = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_CUSTOMER
    const reviewByProvider = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_PROVIDER

    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isVerified = currentCustomer?.attributes?.profile?.publicData?.doLater;
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const instantBooking = currentListing?.attributes?.publicData?.instantBooking;
    const protectedData = currentTransaction?.attributes?.protectedData;
    const isDelivery = protectedData && protectedData?.delivery;

    const onContactUser = () => {
      window.location.href = `/m/${isCustomer ? currentProvider.id.uuid : currentCustomer.id.uuid}`;
    }

    const handleModel = () => {
      this.setState({ openModal2: true });
    };

    const calculateAverage = () => {
      if (reviews?.length === 0) {
        return 0;
      }
      const sum = reviews?.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.rating, 0);
      const average = sum / reviews?.length;
      return average.toFixed(1);
    };


    const onToggleMenu = (currentListing) => {
      this.setState({ isMenuOpen: currentListing });
    }
    const stateDataFn = (tx) => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map((transition) => {
            return transition.attributes.name;
          })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 &&
          transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel:
            isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showActionButtons: isCustomer ? true : instantBooking === "instantBooking" ? true : false,
          primaryButtonProps: isCustomer && instantBooking === "instantBooking" ? markCancelPropsByCustomer : instantBooking === "instantBooking" && isProvider ? markCancelPropsByProvider : markCancelPropsByCustomerBeforeAccept,
          showSaleButtons: instantBooking == "delivery"
            ? isProvider && !isCustomerBanned
            : null,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showActionButtons: moment(start).diff(today, 'day') > 0 ? true : false,
          primaryButtonProps: isCustomer ? markCancelPropsByCustomer : markCancelPropsByProvider,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: reviewByCusmoter ? isProvider : isCompleteState ? true : reviewByProvider ? isCustomer : false
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);
    const isCancellable = isCancelAfterAcceptOrInstantEnabled || (isCustomer && instantBooking === "delivery" && !hasBeenAccepted);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const price = currentListing.attributes.price;
    const bookingSubTitle = price ? `${formatMoney(intl, price)}` : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0
        ? currentListing.images[0]
        : null;

    const declineModal = (
      <Modal
        id="BookingRequestCrad.declineModal"
        isOpen={this.state.isModalOpen}
        onClose={(e) => { e.preventDefault(), this.setState({ isModalOpen: false }) }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        bookingRequest={true}
      >
        <div className={css.modalContainer}>
          <h1>
            <FormattedMessage id="BookingRequestCardInbox.declineHeading" />
          </h1>
          <div>
            <p>
              <FormattedMessage id="BookingRequestCardInbox.declineText" />
            </p>
          </div>
          <div className={css.modalButtons}>
            <Button
              className={css.noButton}
              onClick={(e) => {
                e.preventDefault(), this.setState({ isModalOpen: false });
              }}
            > <FormattedMessage id="BookingRequestCardInbox.noText" />
            </Button>
            <Button
              inProgress={declineInProgress}
              className={css.decline}
              onClick={(e) => {
                e.preventDefault(),
                  onDeclineSale(currentTransaction.id).then(() =>
                    this.setState({ isModalOpen: false })
                  );
              }}
            >
              <span className={css.desktopDecline}><FormattedMessage id="BookingRequestCardInbox.declineButton" /></span>
              <span className={css.mobileDecline}><FormattedMessage id="BookingRequestCardInbox.yesButton" /></span>
            </Button>
          </div>
        </div>
      </Modal>
    );

    const modalHandle = () => {
      this.setState({ isModalOpen: true })
    }

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        onOpenReviewModal={this.onOpenReviewModal}
        modalHandle={modalHandle}
        reviewByProvider={reviewByProvider}
        isCompleteState={isCompleteState}
        reviewByCusmoter={reviewByCusmoter}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        isMobile={isMobile}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const classes = classNames(rootClassName || css.root, className);
    const startPrice =
      currentTransaction?.attributes?.lineItems[0]?.lineTotal?.amount
        ?.toString()
        .slice(0, -2);
    const payinTotal = currentTransaction?.attributes?.payinTotal;
    const payoutTotal = currentTransaction?.attributes?.payoutTotal;
    const userRole = currentTransaction?.customer?.id?.uuid === currentUser?.id?.uuid;
    const { formattedPrice: formattedPayinTotal } = priceData(payinTotal, intl);
    const { formattedPrice: formattedPayoutTotal } = priceData(payoutTotal, intl);
    const customerName = currentCustomer.attributes.profile.displayName;
    const providerName = currentProvider.attributes.profile.displayName;
    const startTime = moment(start).format('LT');
    const startDate = moment(start).format('LL');
    const EndDate = moment(end).format('LL');
    const DurationHours = end?.getHours() - start?.getHours();
    const address = currentTransaction && currentTransaction.listing.attributes.publicData?.location.address ? currentTransaction && currentTransaction.listing.attributes.publicData?.location.address : "500 Bedford Avenue, Brooklyn, New York 11249, United States"

    const getLastTransitions = currentTransaction &&
      currentTransaction.attributes.lastTransition ==
      'transition/complete' ||
      currentTransaction &&
      currentTransaction.attributes.lastTransition == "transition/review-2-by-provider" ||
      currentTransaction &&
      currentTransaction.attributes.lastTransition == "transition/review-1-by-provider" ||
      currentTransaction &&
      currentTransaction.attributes.lastTransition == "transition/review-2-by-customer" ||
      currentTransaction &&
      currentTransaction.attributes.lastTransition == "transition/review-1-by-customer";

    const confirmationPending = !getLastTransitions && instantBooking == "delivery" && isConfirmedState && !isProvider && currentTransaction.attributes.lastTransition == 'transition/confirm-payment'
    const splitted = address && address.split(",");
    const stateName = splitted && splitted.length == 4 ? splitted[2] : splitted && splitted.length == 5 ? splitted[3] : splitted[1];
    const stateName1 = stateName?.split(" ")[1] + " " + stateName?.split(" ")[2]
    const bookingStartDate = moment(currentTransaction?.booking?.attributes.start).format("DD MMM YYYY HH:mm");
    const bookingEndDate = moment(currentTransaction?.booking?.attributes.end).format("DD MMM YYYY HH:mm");
    const currentDate = moment(today).format("DD MMM YYYY HH:mm");
    const isBookingDeclined = (currentTransaction?.attributes.lastTransition === 'transition/decline') ||
    (currentTransaction?.attributes.lastTransition === 'transition/cancel') ||
    (currentTransaction?.attributes.lastTransition === 'transition/expire') ||
    (currentTransaction?.attributes.lastTransition === 'transition/cancel-customer-before-accept') ||
    (currentTransaction?.attributes.lastTransition === TRANSITION_CANCEL_BY_PROVIDER_AFTER_ACCEPT) ||
    (currentTransaction?.attributes.lastTransition === TRANSITION_CANCEL_BY_CUSTOMER_AFTER_ACCEPT);

    const getRemainingTime = (date) => {
      const currentDate = moment();
      // currentDate.format('DD MMM YYYY h:mm:ss A');
      const targetDate = moment(date, 'DD MMM YYYY hh:mm');
      const targetDates = moment(targetDate._i, 'DD MMM YYYY HH:mm')
      const diffInMinutes = targetDates.diff(currentDate, 'minutes');


      if (diffInMinutes <= 0) {
        return { hours: 0, minutes: 0 };
      }

      const remainingHours = Math.floor(diffInMinutes / 60);
      const remainingMinutes = diffInMinutes % 60;

      return { hours: remainingHours, minutes: remainingMinutes };
    };

    // Target date string in the format 'DD MMM YYYY HH:mm

    const startTime1 = startTiming && startTiming?.split(":")[0];
    const startTime2 = moment(bookingStartDate).format('HH:mm').split(":")[0];
    const test = startTime1 - startTime2;
    const newDate = moment(bookingStartDate).add(test, 'hours');
    const targetDateStr = newDate;

    // Calculate the remaining time
    const { hours, minutes } = getRemainingTime(targetDateStr);

    const dayCount = moment(end).diff(start, 'day');
    const data = [
      {
        name: '18-24',
        uv: 100,
        fill: '#fff',
      },
      {
        name: '25-29',
        uv: percentageProgress,
        fill: '#ff4242',
      }
    ];

    const deliveryInfo = (
      <div className={(!isProvider && !isDelivery) ? css.addressCardWrapper : css.deliveryInfoCard}>
        {isProvider ? (
          isDelivery ? (<>
            <b>
              The renter paid for the delivery of this item. A courier will pick it up from you within 30 minutes before the rental start time and bring it back within 30 minutes after the rental end time
            </b>
            {' '}
            <p>
              <b>Expect SMS updates and real-time tracking once the courier is en route to you.</b> (Make sure your phone number is set up under <NamedLink name="ProfileSettingsPage">Profile settings</NamedLink>)
            </p>
          </>
          ) : (<b>
            The renter will come to your location to pick up the item at rental start time and return it at rental end time. Message the renter to confirm the exact public meeting spot.
          </b>)) : (
          isDelivery ? (<>
            <b>
              You paid for the delivery of this item. A courier will deliver it within 30 minutes after the rental start time and pick it back up within 30 minutes before the rental end time.
            </b>
            <p>
              <b>Expect SMS updates and real time tracking once the courier is en route to you</b> (Make sure your phone number is set up under <NamedLink name="ProfileSettingsPage">Profile settings</NamedLink>)
            </p>
          </>
          ) : <>
            <div>
              <p className={css.addressTitle}>
                <FormattedMessage id="TransactionPanel.address" />{' '}
                <span onClick={() => handleModel()}>
                  {' '}
                  <IconCollection name="ADDRESS_INFO" />
                </span>
              </p>
              <p className={css.addressDescription}>
                {address}
              </p>
            </div>
            <a className={css.directionContent} href={`https://www.google.com/maps/search/?api=1&query=${address}`} target="_blank">
              <span>
                {' '}
                <IconCollection name="DIRECTION_LOGO" />
              </span>
              <h6>
                <FormattedMessage id="TransactionPanel.seeDirections" />
              </h6>
            </a>
          </>)}
      </div>
    )

    return (
      <div className={classNames(classes)}>
        <div className={css.titleContents}>
          <div className={css.titleText}>
            {
              isCustomer ? <h2>
                <FormattedMessage id="TransactionPanel.title" />
              </h2>
                :
                <h2>
                  <FormattedMessage id="TransactionPanel.Reservationtitle" />
                </h2>
            }
            {/* {
              isMobile  ? null : */}
            <div className={css.menubar}>
              <Menu contentPosition="left"
                useArrow={false}
                isOpen={this.state.isMenuOpen ? true : false}
                onToggleActive={isOpen => {
                  const listingOpen = isOpen ? currentTransaction : null;
                  onToggleMenu(listingOpen);
                }}
              >
                <MenuLabel
                  className={css.menuLabel}
                  isOpenClassName={css.listingMenuIsOpen}
                >
                  <div className={css.iconWrapper}>
                    <IconCollection name="LISTING_MENU_ICON" />
                  </div>
                </MenuLabel>
                {
                  (currentTransaction && currentTransaction.attributes.lastTransition === "transition/confirm-payment" && isProvider && instantBooking == "delivery") ?
                    <MenuContent rootClassName={css.menuContent}>
                      <MenuItem key="close-listing">
                        <InlineTextButton
                          onClick={() => window.Tawk_API.toggle()}                        >
                          <FormattedMessage id="Contact.Support" />
                        </InlineTextButton>
                      </MenuItem>
                      <MenuItem key="cancel-booking" className={classNames({ [css.hidden]: (isProvider && stateData.showSaleButtons) || !isCancellable })}>
                        <InlineTextButton
                          onClick={() => isCustomer ? this.handleopenModal('cancel-booking') : this.handleCancelBooking(currentTransaction.id)}
                        >
                          <FormattedMessage id="Cancel.Booking" />
                        </InlineTextButton>
                      </MenuItem>

                      <MenuItem key="accept-booking" className={classNames({ [css.hidden]: isProvider })}>
                        <InlineTextButton
                          onClick={() => this.handleopenModal('accept-booking')}
                          inProgress={declineInProgress}
                        >
                          <FormattedMessage id="Accept.Booking" />
                        </InlineTextButton>
                      </MenuItem>
                    </MenuContent>
                    : currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ACCEPT || currentTransaction && currentTransaction.attributes.lastTransition === "transition/confirm-payment" || (instantBooking == "instantBooking" && currentTransaction && currentTransaction.attributes.lastTransition === "transition/confirm-payment") ?
                      <MenuContent rootClassName={css.menuContent}>
                        <MenuItem key="close-listing">
                          <InlineTextButton
                            onClick={() => window.Tawk_API.toggle()}                          >
                            <FormattedMessage id="Contact.Support" />
                          </InlineTextButton>
                        </MenuItem>
                        <MenuItem key="cancel-booking" className={classNames({ [css.hidden]: (isProvider && stateData.showSaleButtons) || !isCancellable })}>
                          <InlineTextButton
                            onClick={() => {
                              if (isCustomer)
                                this.handleopenModal('cancel-booking')
                              else {
                                this.handleCancelBooking(currentTransaction.id);
                                onToggleMenu(null)
                              }
                            }}
                            inProgress={declineInProgress}
                          >
                            <FormattedMessage id="Cancel.Booking" />
                          </InlineTextButton>
                        </MenuItem>
                      </MenuContent> :
                      <MenuContent rootClassName={css.menuContent}>
                        <MenuItem key="close-listing">
                          <InlineTextButton
                            onClick={() => window.Tawk_API.toggle()}                          >
                            <FormattedMessage id="Contact.Support" />
                          </InlineTextButton>
                        </MenuItem>
                      </MenuContent>
                }
              </Menu>
            </div>
            {/* } */}
          </div>
          <hr className={css.dividerLine} />
        </div>

        <div className={css.container}>
          <div className={css.txInfo}>
            <div
              className={classNames(
                css.productTitleMain,
                css.productTitleDesktop
              )}
            >
              <div>
                <h1>{currentTransaction?.listing?.attributes?.title}</h1>
                <p>
                  {categoryLabel(category, currentTransaction?.listing?.attributes?.publicData?.category)}
                </p>
              </div>
              {startPrice ? <h4>
                {userRole ? formattedPayinTotal : formattedPayoutTotal}
              </h4> : <h4>{bookingSubTitle}</h4>}
            </div>
            <div
              className={classNames(
                css.productTitleMain,
                css.productTitleMobile
              )}
            >
              <div className={css.avatarContainer}>
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />
              </div>
              <div>
                <h1>{currentTransaction?.listing?.attributes?.title}</h1>
                <p>
                  {categoryLabel(category, currentTransaction?.listing?.attributes?.publicData?.category)}
                </p>
                {startPrice ? <h4><h4 style={{ color: '#000' }}>Total:</h4> {userRole ? formattedPayinTotal : formattedPayoutTotal}</h4> : <h4>{bookingSubTitle}</h4>}

              </div>
            </div>
            {/* {isConfirmedState && isProvider && instantBooking == "delivery" ? (
              <>
                <h3 className={css.mobileDescriptionTitle}>Description</h3>
                <p className={css.descriptionText}>
                  {currentTransaction.listing.attributes.description}
                </p>
              </>
            ) : null} */}
            {
              isEnquiryState ?
                null :
                <>
                  <div className={css.dateSection}>
                    <div className={css.startTime}>
                      <h6><FormattedMessage id="TransactionPanel.duration" /></h6>
                      <p>

                        {DurationHours > 1
                          ? `${DurationHours} Hours -`
                          : `${moment(end).diff(start, dayCount < 2 ? 'Day' : 'Days')} ${dayCount < 2 ? 'Day' : 'Days'} - `}{' '}
                        {moment(end).diff(start, dayCount < 2 ? 'Day' : 'Days') > 0 ? (
                          <>{startTiming ? startTiming : null}</>
                        ) : (
                          startTime
                        )}
                      </p>
                    </div>
                    <div className={css.endDate}>
                      <h6><FormattedMessage id="TransactionPanel.startAndEnd" /></h6>
                      <p>{`${startDate} - ${EndDate}`}</p>
                    </div>
                  </div>
                  {
                    isConfirmedState && isProvider && instantBooking == "delivery" ?
                      null :
                      <>
                        {(currentTransaction &&
                          currentTransaction.attributes.lastTransition ==
                          'transition/accept' && (days > 0 || hour > 0 || mint > 0 || seconds > 0)) ||
                          (currentTransaction &&
                            currentTransaction.attributes.lastTransition ==
                            'transition/confirm-payment' &&
                            currentTransaction &&
                            currentTransaction.attributes.processName ==
                            'instant-booking-process' && (days > 0 || hour > 0 || mint > 0 || seconds > 0)) ? (
                          <div className={classNames(css.statusCardsDesktop)}>
                            <div className={classNames(css.statusDaysLeft)}>
                              <div>
                                <p className={css.statusTitle}>
                                  {hours <= 24 ? <>{hour} {hour <= 1 ? "Hour" : "Hours"} : {mint} {mint <= 1 ? "Minute" : "Minutes"} left</> : <>{moment(bookingStartDate).startOf('day').diff(moment(currentDate).startOf('day'), 'day')} Days : {hour} {hour <= 1 ? "Hour" : "Hours"} : {mint} {mint <= 1 ? "Minute" : "Minutes"} left</>} {hours <= 24 ? "" : ""}
                                </p>
                                <p className={css.statusDescription}>
                                  <FormattedMessage id="TransactionPanel.startUntil" />

                                </p>
                              </div>
                              <div className={css.daysLogo}>
                                <span>{hours <= 24 ? <>{hour}:{mint}</> : <>{moment(bookingStartDate).startOf('day').diff(moment(currentDate).startOf('day'), 'day')}D</>}</span>
                                <progress
                                  value={percentageProgress}
                                  // className={css.progress}
                                  maxValue={100}
                                  minValue={0}
                                />
                                <ResponsiveContainer width="100%" height="100%">
                                  <RadialBarChart cx="50%" cy="50%" innerRadius="10%" outerRadius="80%" barSize={10} data={data}>
                                    <RadialBar
                                      minAngle={15}
                                      // label={{ position: 'insideStart', fill: '#fff' }}
                                      background
                                      clockWise
                                      dataKey="uv"
                                    />
                                    {/* <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} /> */}
                                  </RadialBarChart>
                                </ResponsiveContainer>
                              </div>
                            </div>
                          </div>
                        ) : isBookingDeclined ? (
                          <div className={classNames(css.statusCardsDesktop)}>
                            <div className={classNames(css.statusCancel)}>
                              <div>
                                <p className={css.statusTitle}> <FormattedMessage id="TransactionPanel.bookingText" /></p>
                                <p className={css.statusDescription}><FormattedMessage id="TransactionPanel.cancelText" /></p>
                              </div>
                              <span>
                                <IconCollection name="CANCELLED_ICON" />
                              </span>
                            </div>
                          </div>
                        ) : currentTransaction &&
                          currentTransaction.attributes.lastTransition ==
                          'transition/complete' || currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/review-2-by-provider" || currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/review-1-by-provider" || currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/review-2-by-customer" || currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/review-1-by-customer" ||
                          currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/expire-review-period" ||
                          currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/expire-provider-review-period" ||
                          currentTransaction &&
                          currentTransaction.attributes.lastTransition == "transition/expire-customer-review-period"
                          ? (
                            <div className={classNames(css.statusCardsDesktop)}>
                              <div className={classNames(css.statusCompleted)}>
                                <div>
                                  <p className={css.statusTitle}><FormattedMessage id="TransactionPanel.bookingText" /></p>
                                  <p className={css.statusDescription}><FormattedMessage id="TransactionPanel.completeText" /></p>
                                </div>
                                <span>
                                  <IconCollection name="COMPLETED_ICON" />
                                </span>
                              </div>
                            </div>
                          ) : (currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ACCEPT && (days == 0 && hour == 0)) || (currentTransaction &&
                            currentTransaction.attributes.lastTransition ==
                            'transition/confirm-payment' &&
                            currentTransaction &&
                            currentTransaction.attributes.processName ==
                            'instant-booking-process' && (days == 0 && hour == 0)) ? (
                            <div className={classNames(css.statusCardsDesktop)}>
                              <div className={classNames(css.statusDaysLeft)}>
                                <div>
                                  <p className={css.statusTitle}>
                                    {selectedTime == "sixHours" ?
                                      <>{this.state.hourss} {this.state.hourss <= 1 ? "Hour" : "Hours"} : {this.state.mints} {this.state.mints <= 1 ? "Minute" : "Minutes"} left</>
                                      : moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day') > 1 ?
                                        <>
                                          {moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day')} Days : {this.state.timeDifference} {this.state.timeDifference <= 1 ? "Hour" : "Hours"} : {this.state.timeInmints} {this.state.timeInmints <= 1 ? "Minute" : "Minutes"} left
                                        </>
                                        :
                                        <>{this.state.timeDifference} {this.state.timeDifference <= 1 ? "Hour" : "Hours"} : {this.state.timeInmints} {this.state.timeInmints <= 1 ? "Minute" : "Minutes"} left</>
                                    }{" "}
                                    {selectedTime == "sixHours" ||
                                      moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day') <= 24 ?
                                      ""
                                      : ""}
                                  </p>
                                  <p className={css.statusDescription}>
                                    <FormattedMessage id="TransactionPanel.endUntil" />

                                  </p>
                                </div>
                                <div className={css.daysLogo}>
                                  <span>{selectedTime == "sixHours" ?
                                    <>{this.state.hourss}:{this.state.mints}</>
                                    : moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day') > 1 ?
                                      <>{moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day')}D</>
                                      : <>{this.state.timeDifference}:{this.state.timeInmints}</>}</span>
                                  {/* <progress
                                    value={percentageProgress}
                                    // className={css.progress}
                                    maxValue={100}
                                    minValue={0}
                                  /> */}
                                  <ResponsiveContainer width="100%" height="100%">
                                    <RadialBarChart cx="50%" cy="50%" innerRadius="10%" outerRadius="80%" barSize={10} data={data}>
                                      <RadialBar
                                        minAngle={15}
                                        // label={{ position: 'insideStart', fill: '#fff' }}
                                        background
                                        clockWise
                                        dataKey="uv"
                                      />
                                      {/* <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} /> */}
                                    </RadialBarChart>
                                  </ResponsiveContainer>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className={classNames(css.statusCardsDesktop)}>
                              <div className={classNames(css.statusPending)}>
                                <div>
                                  <p className={css.statusTitle}>Booking</p>
                                  <p className={css.statusDescription}>
                                    <FormattedMessage id="TransactionPanel.confirmationPending" />
                                  </p>
                                </div>
                                <span>
                                  <IconCollection name="STATUS_LOGO" />
                                </span>
                              </div>
                            </div>
                          )}
                      </>
                  }
                </>
            }
            <Modal
              id={"TransactionPanel.booking"}
              isOpen={this.state.openModal}
              transactionPanel={true}
              addPhoto={true}
              onClose={() => {
                this.setState({ openModal: false });
              }}
              onManageDisableScrolling={() => { }}
            >
              {this.state.ModalType == 'Cancel' ? (
                <>
                  <div className={css.cancelModalContainer}>
                    <h2 className={css.cancelBooking}>
                      <FormattedMessage id="TransactionPanel.cancelBooking" />
                    </h2>
                    <h6>
                      <FormattedMessage id="TransactionPanel.areYouSure" />
                    </h6>
                    <p>
                      <FormattedMessage
                        values={{
                          learnMore: (
                            <span className={css.learnText}><FormattedMessage id="TransactionPanel.learnMore" /></span>
                          ),
                        }}
                        id="TransactionPanel.cancellingOutside"
                      />
                    </p>
                    <div className={css.cancelButtons}>
                      <button
                        className={css.buttonNo}
                        onClick={() => this.setState({ openModal: false })}
                      >
                        <FormattedMessage id="TransactionPanel.noText" />
                      </button>
                      <button
                        className={css.buttonYesCancel}
                        onClick={() =>
                          this.handleCancelBooking(currentTransaction.id)
                        }
                      ><FormattedMessage id="TransactionPanel.yesText" />

                      </button>
                    </div>
                  </div>
                </>
              ) : this.state.ModalType == 'Accept' ? (
                <>
                  <div className={css.acceptBookingModal}>
                    <h2 className={css.accptBooking}><FormattedMessage id="TransactionPanel.bookingAccept" /></h2>
                    <h6><FormattedMessage id="TransactionPanel.bookingAcceptText" /></h6>
                    <div className={css.cancelButtons}>
                      <button
                        className={css.buttonNo}
                        onClick={() => this.setState({ openModal: false })}
                      >
                        <FormattedMessage id="TransactionPanel.noText" />
                      </button>
                      <button
                        className={css.buttonYes}
                        onClick={() =>
                          this.handleAcceptBooking(currentTransaction.id)
                        }
                      ><FormattedMessage id="TransactionPanel.yesText" />
                      </button>
                    </div>
                  </div>
                </>
              ) : this.state.ModalType == 'Support' ? (
                <div className={css.contactSupport}>
                  <h2><FormattedMessage id="TransactionPanel.contactSupport" /></h2>
                  <h6><FormattedMessage id="TransactionPanel.contactSupportText" />
                  </h6>
                </div>
              ) : null}
            </Modal>
            {!isEnquiryState ? (
              <>
                <div className={classNames(css.avatarWrapperProviderDesktop, instantBooking == "delivery" && isConfirmedState && isProvider && css.extraPadding, confirmationPending && css.confirmationPending)}>
                  {confirmationPending ? <div
                    className={classNames(
                      css.avatarWrapperProvider,
                      css.anotherProvider
                    )}
                  >
                    <div className={css.providerSectionFirst}>
                      <div className={css.avatarImage}>
                        <AvatarLarge
                          user={currentProvider}
                          className={css.avatarDesktop}
                        />
                      </div>
                      <div>
                        <p>
                          {' '}
                          {isProvider ? (
                            <FormattedMessage id="TransactionPanel.rentedBy" />
                          ) : (
                            <FormattedMessage id="TransactionPanel.rentedFrom" />
                          )}
                        </p>
                        <h6>{isCustomer ? providerName : customerName}</h6>
                        {isProvider ? (
                          <div className={css.idVerified}>
                            {
                              !isVerified &&
                              <>
                                <IconCollection name="TRANSACTION_VERIFIED" />
                                <h5>
                                  {' '}
                                  <FormattedMessage id="TransactionPanel.idVerified" />
                                </h5>
                              </>
                            }
                          </div>
                        ) : (
                          <div className={css.listingRating}>
                            <IconCollection name="RATING_STAR" />
                            <h4 className={css.ratingText}>{calculateAverage()} ({reviews && reviews.length})</h4>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={css.providerSectionSecond}>
                      <span>
                        <InlineTextButton
                          rootClassName={css.contactLink}
                          onClick={onContactUser}
                          enforcePagePreloadFor="SignupPage"
                        >
                          <IconCollection name="TRANSACTION_MESSAGE" />
                        </InlineTextButton>
                      </span>
                    </div>
                  </div> :
                    <div className={classNames(css.avatarWrapperProvider)}>
                      <div className={css.providerSectionFirst}>
                        <div className={css.avatarImage}>
                          <AvatarLarge
                            user={isCustomer ? currentProvider : currentCustomer}
                            className={css.avatarDesktop}
                            hash={isCustomer ? '' : `#${REVIEW_TYPE_OF_CUSTOMER}`}
                          />
                        </div>
                        <div>
                          <p>
                            {' '}
                            {isCustomer ? "Rented From : " : <FormattedMessage id="TransactionPanel.rentedBy" />}
                          </p>
                          <h6>{isCustomer ? providerName : customerName}</h6>
                          {isProvider ? (
                            <div className={css.idVerified}>
                              {
                                !isVerified &&
                                <>
                                  <IconCollection name="TRANSACTION_VERIFIED" />
                                  <h5>
                                    {' '}
                                    <FormattedMessage id="TransactionPanel.idVerified" />
                                  </h5>
                                </>
                              }
                            </div>
                          ) : (
                            <div className={css.listingRating}>
                              <IconCollection name="RATING_STAR" />
                              <h4 className={css.ratingText}>{calculateAverage()} ({reviews && reviews.length})</h4>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={css.providerSectionSecond}>
                        <span>
                          <InlineTextButton
                            rootClassName={css.contactLink}
                            onClick={onContactUser}
                            enforcePagePreloadFor="SignupPage"
                          >
                            <IconCollection name="TRANSACTION_MESSAGE" />
                          </InlineTextButton>
                        </span>
                      </div>
                    </div>}
                  {!isConfirmedState || instantBooking == "instantBooking" ?
                    deliveryInfo : null}
                </div>

                <div className={classNames(css.avatarWrapperProviderMobile)}>
                  <div className={classNames(css.avatarWrapperProvider)}>
                    <div className={css.providerSectionFirst}>
                      <div className={css.providerDetails}>
                        <div>
                          <p>    {isProvider ? (
                            <FormattedMessage id="TransactionPanel.rentedBy" />
                          ) : (
                            <FormattedMessage id="TransactionPanel.rentedFrom" />
                          )}</p>
                          <AvatarLarge
                            user={isCustomer ? currentProvider : currentCustomer}
                            className={css.avatarDesktop}
                            hash={isCustomer ? '' : `#${REVIEW_TYPE_OF_CUSTOMER}`}
                          />
                        </div>
                        <div className={css.providerName}>
                          <h6>{isCustomer ? providerName : customerName}</h6>
                          {isProvider ? (
                            <div className={css.idVerified}>
                              {
                                !isVerified &&
                                <>
                                  <IconCollection name="TRANSACTION_VERIFIED" />
                                  <h5>
                                    {' '}
                                    <FormattedMessage id="TransactionPanel.idVerified" />
                                  </h5>
                                </>
                              }
                            </div>
                          ) : (
                            <div className={css.listingRating}>
                              <IconCollection name="RATING_STAR" />
                              <h4 className={css.ratingText}>{calculateAverage()} ({reviews && reviews.length})</h4>
                            </div>
                          )}
                        </div>
                      </div>
                      <span className={css.transactionMessage} onClick={onContactUser} >
                        <IconCollection name="TRANSACTION_MESSAGE" />
                      </span>
                    </div>

                  </div>
                  {
                    !isConfirmedState || instantBooking == "instantBooking" ?
                      deliveryInfo : null
                  }
                </div>
              </>
            ) : null}
            {
              isEnquiryState ?
                <>
                  <FeedSection
                    rootClassName={css.feedContainer}
                    currentTransaction={currentTransaction}
                    currentUser={currentUser}
                    fetchMessagesError={fetchMessagesError}
                    fetchMessagesInProgress={fetchMessagesInProgress}
                    initialMessageFailed={initialMessageFailed}
                    messages={messages}
                    oldestMessagePageFetched={oldestMessagePageFetched}
                    onOpenReviewModal={this.onOpenReviewModal}
                    onShowMoreMessages={() =>
                      onShowMoreMessages(currentTransaction.id)
                    }
                    totalMessagePages={totalMessagePages}
                  />
                  {showSendMessageForm ? (
                    <SendMessageForm
                      formId={this.sendMessageFormName}
                      rootClassName={css.sendMessageForm}
                      messagePlaceholder={sendMessagePlaceholder}
                      inProgress={sendMessageInProgress}
                      sendMessageError={sendMessageError}
                      onFocus={this.onSendMessageFormFocus}
                      onBlur={this.onSendMessageFormBlur}
                      onSubmit={this.onMessageSubmit}
                    />
                  ) : (
                    <div className={css.sendingMessageNotAllowed}>
                      {sendingMessageNotAllowed}
                    </div>
                  )}

                  {stateData.showSaleButtons ? (
                    <div className={css.mobileActionButtons}>{saleButtons}</div>
                  ) : null}

                  {/* {stateData.showActionButtons ? (
                    <div className={css.desktopActionButtons}>{actionButtons}</div>
                  ) : null} */}
                </>
                : null
            }
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <div className={css.avatarContainerDesktop}>
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />
              </div>
              {isBookingDeclined ? 
              <h6 style={{ marginBottom: "10px" }}>Booking request was declined. You have not been charged.</h6> :
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                transactionPanel={true}
                selectedTime={selectedTime}
                DurationHours={DurationHours}
                diffBetweenDays={diffBetweenDays}
              />}
              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {declineModal}
            </div>
          </div>
          {
            isEnquiryState ?
              null :
              <>
                {
                  isConfirmedState && isProvider && instantBooking == "delivery" ?
                    null :
                    <>
                      {(currentTransaction &&
                        currentTransaction.attributes.lastTransition ==
                        'transition/accept' && (days > 0 || hour > 0 || mint > 0 || seconds > 0)) ||
                        (currentTransaction &&
                          currentTransaction.attributes.lastTransition ==
                          'transition/confirm-payment' &&
                          currentTransaction &&
                          currentTransaction.attributes.processName ==
                          'instant-booking-process' && (days > 0 || hour > 0 || mint > 0 || seconds > 0)) ? (
                        <div className={classNames(css.statusCardsMobile)}>
                          <div className={classNames(css.statusDaysLeft)}>
                            <div>
                              <p className={css.statusTitle}>
                                {hours <= 24 ? <>{hour} {hour <= 1 ? "Hour" : "Hours"} : {mint} {mint <= 1 ? "Minute" : "Minutes"} left</> : <>{moment(bookingStartDate).startOf('day').diff(moment(currentDate).startOf('day'), 'day')} Days : {hour} {hour <= 1 ? "Hour" : "Hours"} : {mint} {mint <= 1 ? "Minute" : "Minutes"} left</>} {hours <= 24 ? "" : ""}
                              </p>
                              <p className={css.statusDescription}>
                                <FormattedMessage id="TransactionPanel.startUntil" />
                              </p>
                            </div>
                            <div className={css.daysLogo}>
                              <span>{hours <= 24 ? <>{hour}:{mint}</> : <>{moment(bookingStartDate).startOf('day').diff(moment(currentDate).startOf('day'), 'day')}D</>}</span>
                              <ResponsiveContainer width="100%" height="100%">
                                <RadialBarChart cx="50%" cy="50%" innerRadius="10%" outerRadius="80%" barSize={10} data={data}>
                                  <RadialBar
                                    minAngle={15}
                                    // label={{ position: 'insideStart', fill: '#fff' }}
                                    background
                                    clockWise
                                    dataKey="uv"
                                  />
                                  {/* <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} /> */}
                                </RadialBarChart>
                              </ResponsiveContainer>
                            </div>
                          </div>
                        </div>
                      ) : isBookingDeclined ? (
                        <div className={classNames(css.statusCardsMobile)}>
                          <div className={classNames(css.statusCancel)}>
                            <div>
                              <p className={css.statusTitle}> <FormattedMessage id="TransactionPanel.bookingText" /></p>
                              <p className={css.statusDescription}><FormattedMessage id="TransactionPanel.cancelText" /></p>
                            </div>
                            <span>
                              <IconCollection name="CANCELLED_ICON_MOBILE" />
                            </span>
                          </div>
                        </div>
                      ) : currentTransaction &&
                        currentTransaction.attributes.lastTransition ==
                        'transition/complete' || currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/review-2-by-provider" || currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/review-1-by-provider" || currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/review-2-by-customer" || currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/review-1-by-customer" ||
                        currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/expire-review-period" ||
                        currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/expire-provider-review-period" ||
                        currentTransaction &&
                        currentTransaction.attributes.lastTransition == "transition/expire-customer-review-period"
                        ? (
                          <div className={classNames(css.statusCardsMobile)}>
                            <div className={classNames(css.statusCompleted)}>
                              <div>
                                <p className={css.statusTitle}> <FormattedMessage id="TransactionPanel.bookingText" /></p>
                                <p className={css.statusDescription}><FormattedMessage id="TransactionPanel.completeText" /></p>
                              </div>
                              <span>
                                <IconCollection name="COMPLETED_ICON" />
                              </span>
                            </div>
                          </div>
                        ) : (currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ACCEPT && (days == 0 && hour == 0)) || (currentTransaction &&
                          currentTransaction.attributes.lastTransition ==
                          'transition/confirm-payment' &&
                          currentTransaction &&
                          currentTransaction.attributes.processName ==
                          'instant-booking-process' && (days == 0 && hour == 0)) ? (
                          <div className={classNames(css.statusCardsMobile)}>
                            <div className={classNames(css.statusDaysLeft)}>
                              <div>

                                <p className={css.statusTitle}>
                                  {selectedTime == "sixHours" ?
                                    <>{this.state.hourss} {this.state.hourss <= 1 ? "Hour" : "Hours"} : {this.state.mints} {this.state.mints <= 1 ? "Minute" : "Minutes"} left</>
                                    : moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day') > 1 ?
                                      <>
                                        {moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day')} Days : {this.state.timeDifference} {this.state.timeDifference <= 1 ? "Hour" : "Hours"} : {this.state.timeInmints} {this.state.timeInmints <= 1 ? "Minute" : "Minutes"} left
                                      </>
                                      :
                                      <>{this.state.timeDifference} {this.state.timeDifference <= 1 ? "Hour" : "Hours"} :{this.state.timeInmints} {this.state.timeInmints <= 1 ? "Minute" : "Minutes"} left</>
                                  }{" "}
                                  {selectedTime == "sixHours" ||
                                    moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day') <= 24 ?
                                    ""
                                    : ""}
                                </p>
                                <p className={css.statusDescription}>
                                  <p > <FormattedMessage id="TransactionPanel.endUntil" /></p>
                                </p>
                              </div>
                              <div className={css.daysLogo}>
                                <span>{selectedTime == "sixHours" ?
                                  <>{this.state.hourss}:{this.state.mints}</>
                                  : moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day') > 1 ?
                                    <>
                                      {moment(bookingEndDate).startOf('day').diff(moment(bookingStartDate).startOf('day'), 'day')}D
                                    </>
                                    : <>{this.state.timeDifference}:{this.state.timeInmints}</>}</span>
                                <ResponsiveContainer width="100%" height="100%">
                                  <RadialBarChart cx="50%" cy="50%" innerRadius="10%" outerRadius="80%" barSize={10} data={data}>
                                    <RadialBar
                                      minAngle={15}
                                      // label={{ position: 'insideStart', fill: '#fff' }}
                                      background
                                      clockWise
                                      dataKey="uv"
                                    />
                                    {/* <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} /> */}
                                  </RadialBarChart>
                                </ResponsiveContainer>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className={classNames(css.statusCardsMobile)}>
                            <div className={classNames(css.statusPending)}>
                              <div>
                                <p className={css.statusTitle}> <FormattedMessage id="TransactionPanel.bookingText" /></p>
                                <p className={css.statusDescription}>
                                  <FormattedMessage id="TransactionPanel.confirmationPending" />
                                </p>
                              </div>
                              <span>
                                <IconCollection name="STATUS_LOGO_MOBILE" />
                              </span>
                            </div>
                          </div>
                        )}
                    </>
                }
              </>
          }
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          transaction={transaction}
          startDate={startDate}
          endDate={EndDate}
          image={firstImage}
        />
        <Modal
          onManageDisableScrolling={() => { }}
          isOpen={this.state.reviewSubmitted}
          reviewFeedback={true}
          className={css.feedbackModal}
        >
          <IconCollection name="FEEDBACK_ICON" />
          <h2><FormattedMessage id="TransactionPanel.feedback" /></h2>
          <NamedLink
            name="LandingPage"
            className={classNames(css.continueLink)}
          ><FormattedMessage id="TransactionPanel.continue" />
          </NamedLink>
        </Modal>
        <Modal
          onManageDisableScrolling={() => { }}
          isOpen={this.state.openModal2}
          rentalAddress={true}
          onClose={() => {
            this.setState({ openModal2: false });
          }}
        >

          <div className={css.addressContainer}>
            <div>
              <IconCollection name="ADDRESS_INFO_MODAL" />
            </div>
            <div>
              <p>
                <FormattedMessage id="TransactionPanel.addressText" />
              </p>
              <p className={css.extraText}>
                For the safety of our users, we recommend only meeting in public places near the rental location.
              </p>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
